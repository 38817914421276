<template>
  <div class="edrea_tm_section" id="portfolio">
    <div class="section_inner">
      <div class="edrea_tm_portfolio swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Creative <span class="coloring">Portfolio</span></h3>
        </div>
        <div class="portfolio_list gallery_zoom">
          <swiper :loop="false" :slidesPerView="1" :spaceBetween="0" :loopAdditionalSlides="1" :autoplay="{
            delay: 6000,
          }" :navigation="{
            nextEl: '.my_next',
            prevEl: '.my_prev',
          }" :pagination="pagination" :breakpoints="{
            700: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }" @slideChange="onSlideChange" :modules="modules" class="swiper-container">

            <!-- <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/3.jpg"></div>
                </div>
                <div class="details">
                  <h3>Mockup Cream</h3>
                  <span>Soundcloud</span>
                </div>
                <a
                  class="edrea_tm_full_link soundcloude_link mfp-iframe audio"
                  href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/471954807&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                ></a>
              </div>
            </swiper-slide>  -->
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/4.jpg"></div>
                </div>
                <div class="details">
                  <h3>Elevate Hospitality</h3>
                  <span>Web Site</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" data-img-url="img/portfolio/4-1.jpg"
                        style="background-image: url('img/portfolio/4-1.jpg')"></div>
                    </div>
                    <div class="details">
                      <h3>Elevate Hospitality</h3>
                      <span>Detail</span>
                    </div>
                    <div class="text">
                      <p>
                        We help hospitality businesses create transformative experiences that set new industry
                        standards.
                        One of my clients, Elevate Hospitality, embodies this mission by leveraging innovation and
                        expertise to redefine hospitality excellence.
                      </p>

                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div class="main" data-img-url="img/portfolio/elevate-thum.png"></div>
                              <a class="edrea_tm_full_link zoom" href="img/portfolio/elevate-cover.jpg"
                                target="_blank"></a>
                            </div>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/autoterminal-japan.jpg"></div>
                </div>
                <div class="details">
                  <h3>Autoterminal Japan</h3>
                  <span>Mobile App</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" data-img-url="img/portfolio/autoterminal-japan.jpg"
                        style="background-image: url('img/portfolio/autoterminal-japan.jpg')"></div>
                    </div>
                    <div class="details">
                      <h3>Autoterminal Japan</h3>
                      <span>Detail</span>
                    </div>
                    <div class="text">
                      <p>
                        We developed two mobile applications using Flutter for Autoterminal Japan Ltd., a leading
                        automobile inspection company. The Inspector and Exporter apps, which are actively used by 100
                        users daily, have increased the company's productivity with these two apps.
                      </p>

                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div class="main" data-img-url="img/portfolio/autoterminal-japan.png"></div>
                              <a class="edrea_tm_full_link zoom" href="img/portfolio/autoterminal-japan.png"
                                target="_blank"></a>
                            </div>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/viwaha.png"></div>
                </div>
                <div class="details">
                  <h3>Viwaha</h3>
                  <span>Mobile App</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" data-img-url="img/portfolio/viwaha.png"
                        style="background-image: url('img/portfolio/viwaha.png')"></div>
                    </div>
                    <div class="details">
                      <h3>Viwaha</h3>
                      <span>Detail</span>
                    </div>
                    <div class="text">
                      <p>
                        We developed Viwaha, a Sri Lankan marketplace app that connects engaged couples with local
                        wedding professionals. With Viwaha, millions of couples around the world can search, compare,
                        and book from a directory of vendors. The app provides inspiration, ideas, and budget-friendly
                        options for services like catering and photography. Additionally, I created the backend
                        dashboard to ensure seamless management. Log on to Viwaha, and effortlessly plan the wedding of
                        your dreams!
                      </p>

                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div class="main" data-img-url="img/portfolio/viwaha-thum.png"></div>
                              <a class="edrea_tm_full_link zoom" href="img/portfolio/viwaha-cover.png"
                                target="_blank"></a>
                            </div>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>

            <!-- <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/6.jpg"></div>
                </div>
                <div class="details">
                  <h3>Sweet Cheery</h3>
                  <span>Image</span>
                </div>
                <a class="edrea_tm_full_link zoom" href="img/portfolio/6.jpg"></a>
              </div>
            </swiper-slide> -->
            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="current">0</span>
                <span class="pagination_progress"><span class="all"><span style="
                        transform: translate3d(0px, 0px, 0px) scaleX(0.75)
                          scaleY(1);
                      "></span></span></span>
                <span class="total">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"><i class="icon-left-open-1"></i></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"><i class="icon-right-open-1"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// import Swiper core and required modules

// Import Swiper Vue.js components
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `PortfolioComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(current, total);
        },
      },
    };
  },
};
</script>
