<template>
  <div class="edrea_tm_section animated" id="home">
    <div class="section_inner">
      <div class="edrea_tm_home">
        <h3 class="name">Synth <span class="coloring">Solutions</span></h3>
        <h4 class="coloring">Get Ready for Our Next Innovation</h4>
        <div class="countdown" style="margin-top: 10px;">
          <h3 class="countdown-text">{{ countdownText }}</h3>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { navFuntions } from "@/navFuntions";

export default {
  name: `HomeComponent`,
  data() {
    return {
      navFuntions,
      countdownText: '', // Initialize countdown text
    };
  },
  mounted() {
    // Calculate the remaining time and update the countdownText
    const eventDate = new Date('2024-08-01'); // Set the target date for the countdown
    setInterval(() => {
      const now = new Date().getTime();
      const distance = eventDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countdownText = `${days}d ${hours}h ${minutes}m ${seconds}s`; // Update countdown text
    }, 1000);
  },
};
</script>
